import htmx from 'htmx.org';
import IMask from 'imask';

document.addEventListener('DOMContentLoaded', () => {
    const openBtn = document.getElementById('open-filters');
    const closeBtn = document.getElementById('close-filters');
    const applyBtn = document.getElementById('apply-filters');
    const mobileFilters = document.getElementById('mobile-filters');
    const backdrop = document.getElementById('backdrop');

    if (openBtn && mobileFilters && backdrop) {
        openBtn.addEventListener('click', () => {
            mobileFilters.classList.remove('translate-x-full');
            mobileFilters.classList.add('translate-x-0');
            backdrop.classList.remove('hidden');
        });

        if (closeBtn) {
            closeBtn.addEventListener('click', () => {
                mobileFilters.classList.remove('translate-x-0');
                mobileFilters.classList.add('translate-x-full');
                backdrop.classList.add('hidden');
            });
        }

        if (applyBtn) {
            applyBtn.addEventListener('click', () => {
                mobileFilters.classList.remove('translate-x-0');
                mobileFilters.classList.add('translate-x-full');
                backdrop.classList.add('hidden');
            });
        }

        backdrop.addEventListener('click', (event) => {
            if (!event.target.closest('#mobile-filters')) {
                mobileFilters.classList.remove('translate-x-0');
                mobileFilters.classList.add('translate-x-full');
                backdrop.classList.add('hidden');
            }
        });

        mobileFilters.addEventListener('click', (event) => {
            if (event.target.closest('.dropdown') || event.target.closest('input[type="checkbox"]')) {
                event.stopPropagation();
            }
        });
    }

    window.toggleDropdown = function(dropdownId) {
        const dropdown = document.getElementById(dropdownId);
        if (dropdown) { 
            const isVisible = dropdown.classList.contains('hidden');
            
            document.querySelectorAll('.dropdown').forEach(function(d) {
                d.classList.add('hidden');
            });

            if (isVisible) {
                dropdown.classList.remove('hidden');
            } else {
                dropdown.classList.add('hidden');
            }
        }
    };

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(function(checkbox) {
        checkbox.addEventListener('click', function(event) {
            event.stopPropagation();
        });
    });
});
